<template>
    <div class="profile_notes" v-if="modelValue" :style="`--editbar-color: ${formDetails.survey_info ? formDetails.survey_info.accent_color : '#f2a31d'}`">
        <div class="edit_details">
            <button class="close_btn" @click="closeModal()"><i class="fas fa-times"></i></button>
            <div class="edit_wpr">
                <div class="doc_container">
                    <div class="letter_head">
                        <button type="button" class="down_btn" @click="gererateReport()"><i class="fas fa-download"></i></button>
                        <div class="comp_name">
                            <h3>{{ user.businessname }}</h3>
                            <address>{{ user.address }}</address>
                        </div>
                        <img class="comp_logo" v-if="user.logo" :src="user.logo ? user.logo : require('@/assets/images/thumb/default-logo.svg')">
                    </div>
                    <div class="content_body">
                        <div class="form_content">
                            <form-detail-content :form-details="formDetails" :launchpad="false" :contact="formDetails.contact" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import { defineAsyncComponent } from 'vue'
    import { mapState, mapActions } from 'vuex'

    const FormDetailContent = defineAsyncComponent(() => import('@/pages/form/components/FormDetailContent'))

    export default {
        name: 'Contact Form Detail',

        data () {
            return {
                formPdfName: '',
            }
        },

        props: {
            modelValue: Boolean,
            formDetails: Object,
            respondent: Object,
            actionHandler: Function,
        },

        emit: ['update:modelValue'],

        components: {
            FormDetailContent,
        },

        watch: {
            modelValue (val) {
                if (val) {
                    const vm = this;

                    vm.formPdfName = (Date.now()+'-'+vm.formDetails.survey_info.survey_name).replaceAll(' ', '-');
                }
            }
        },

        computed: {
            ...mapState({
                user: state => state.authModule.user,
            }),
        },

        methods: {
            ...mapActions({
                downloadFormResponse: 'formModule/downloadFormResponse',
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            gererateReport () {
                const vm = this;

                const formData = {
                    contact_id: vm.respondent.user_id,
                    survey_id: vm.respondent.survey_id,
                    respondent_id: vm.respondent.respondent_id,
                    file_name: vm.formPdfName,
                }

                vm.downloadFormResponse(formData);
            }
        }
    }
</script>

<style scoped>
    .info_wpr {
        display: flex;
        flex-wrap: wrap;
        margin: 0 0 45px ;
        padding: 25px 23px 35px 23px;
        border: 1px solid #e9e9e9;
        border-radius: 6px;
        /* box-shadow: 0 0 20px rgba(0,0,0,0.08); */
        position: relative;
    }

    /* .info_wpr:before {
        position: absolute;
        content: '';
        left: 0;
        right: 0;
        top: 0;
        height: 4px;
        background: var(--editbar-color, #f2a31d);
        border-radius: 6px 6px 0 0;
    } */

    .info_wpr .border_item {
        flex: 1 0 50%;
        padding: 15px 7px;
    }

    .info_wpr .border_item h5 {
        font-size: 15px !important;
        line-height: 20px;
        color: #121525;
        font-weight: 400;
        margin-bottom: 6px;
    }

    .info_wpr .border_item p {
        /* border: 1px solid #d9d9d9; */
        padding: 0 15px;
        border-radius: 5px;
        height: 50px;
        font-size: 16px;
        line-height: 48px;
        color: #5a5a5a;
    }

    .answer_item {
        padding: 5px;
        font-size: 10px;
        line-height: 18px;
        border: 1px solid #d9d9d9;
        border-radius: 5px;
        margin: 7px 0 15px;
        min-height: 30px;
    }

    .form_details {
        padding: 30px;
        border: 1px solid #e9e9e9;
        border-radius: 6px;
        /* box-shadow: 0 0 20px rgba(0,0,0,0.08); */
        position: relative;
        margin-bottom: 45px;
    }

    /* .form_details:before {
        position: absolute;
        content: '';
        left: 0;
        right: 0;
        top: 0;
        height: 4px;
        background: var(--editbar-color, #f2a31d);
        border-radius: 6px 6px 0 0;
    } */

    .form_details .form_wrapper {
        display: flex;
        flex-direction: column;
        padding: 0 7px;
        border: 1px solid #edecec;
        padding: 20px 20px 10px 20px;
        border-radius: 6px;
        margin-bottom: 25px;
    }

    .form_details :deep(label), .form_details :deep(label p) {
        font-size: 15px;
        line-height: 22px;
        color: #121525 !important   ;
        font-weight: 500;
        margin-bottom: 15px;
    }

    .question_option {
        font-size: 13px;
        line-height: 18px;
        color: #5a5a5a;
        font-weight: 500;
        margin-bottom: 14px;
    }

    .question_option i {
        margin-right: 5px;
    }

    .edit_details {
        width: calc(100% - 320px);
        height: calc(100vh);
        background: #f5f5f5;
        position: fixed;
        top: 0;
        right: 0;
        z-index: 4;
        animation: smoothMove 0.6s;
        animation-timing-function: cubic-bezier(1.0, 0.5);
    }

    @keyframes smoothMove  {

        from  {transform: scale(0);}

        to  {transform: scale(1);}
    }

    .edit_details .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 30px;
        border-bottom: 1px solid #eee;
    }

    .edit_details .header h1 {
        font-size: 27px;
        line-height: 33px;
        font-weight: 500;
    }

    .edit_details .header .close_btn {
        top: 20px;
        left: auto;
        right: 30px;
    }

    .edit_wpr  {
        padding: 30px;
        height: 100vh;
        overflow-y: scroll;
    }

    .edit_details .close_btn {
        left: auto;
        right: 20px;
    }

    .privacy_info {
        display: flex;
        padding: 20px 25px;
        border: 1px solid #bdeafa;
        background: rgba(149, 215, 239, 0.2);
        border-radius: 5px;
        align-items: flex-start;
        margin: 30px 0 20px 0;
    }

    .privacy_info i {
        font-size: 34px;
        color: #f2a31d;
    }

    .privacy_info p {
        font-size: 13px;
        line-height: 18px;
        margin-left: 12px;
    }

    .signature_fld {
        display: flex;
        justify-content: space-between;
        font-family: Caveat,cursive;
        margin: 0 0 20px;
        padding: 10px 0;
        font-size: 20px;
        line-height: 25px;
        border-bottom: 1px solid #d9d9d9;
    }

    .form_details.legal_info label p {
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
        color: #5a5a5a !important;
    }

    .privacy_info p {
        font-size: 13px;
        line-height: 18px;
        margin-left: 12px !important;
    }

    .letter_head {
        text-align: left;
        /* margin-bottom: 30px; */
    }

    .letter_head .comp_logo {
        height: 40px;
        width: auto;
    }

    .container {
        padding: 15px 50px;
        width: 100%;
        text-align: left;
    }

    h1 {
        font-size: 25px;
        line-height: 30px;
        font-weight: 500;
        color: #121525;
        margin: 0;
        text-align: left;
        padding: 0 50px 5px 50px;
    }

    :deep(.form-title) {
        /* margin-top: 0 !important; */
    }

    @media(max-width: 1399px){
        .edit_details{
            width: calc(100% - 100px);
        }
    }
    @media(max-width: 991px){
        .edit_details{
            width: 100%;
        }
    }
</style>
